import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
import {Toast, Swipe, SwipeItem, Icon, ActionSheet, Popup,Lazyload } from 'vant';
import waterfall from "vue-waterfall2";
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)
Vue.use(waterfall);
Vue.use(Vant, Toast, Swipe, SwipeItem, Icon, ActionSheet, Popup );
Vue.use(Lazyload);
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
